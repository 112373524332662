<template>
  <div class="blacklist-page">
    <vuestic-widget >
      <template slot="header">
        <div class="w-100 d-flex justify-content-between align-items-center">
          <span class="title">Embedded Forms</span>
          <div class="d-flex justify-content-end align-items-center">
            <router-link type="button" class="btn btn-primary btn-sm" :to="{ name: 'business.embedded-forms.create' }">
              Add Form <i class="fa fa-plus"></i>
            </router-link>
          </div>
        </div>
      </template>
      <div>
      <div class="row">
        <div class="col-md-12">
          <div class="pb-2 mb-3">
          </div>
          <div class="d-flex justify-content-center" v-if="isLoading">
            <span class="atom-spinner-wrapper">
              <atom-spinner slot="loading" :animation-duration="1500" :size="120" color="rgb(53,120,198)"/>
            </span>
          </div>
          <div class="col-md-12" v-else>
            <datatable v-bind="ipTableOptions" class="le-datatable"/>
          </div>
        </div>
      </div>
      </div>
    </vuestic-widget>

    <vuestic-modal 
      :isOpen="selectedForm !== null"
      :hideDefaultActions="true"
      @cancel="() => selectedForm = null"
      okText="Copy"
      cancelText="Close"
      @ok="copyEmbedCode"
    >
      <span slot="title">Install HTML Code</span>
      <strong>Copy the widget code and install it on Your website</strong>

      <textarea v-if="selectedForm" class="form-control" rows="4" id="embedCode" readonly v-model="selectedForm.script"></textarea>
    </vuestic-modal>

    <vuestic-modal
      :isOpen="isOpenModalDelete"
      @ok="handleDeleteEmbeddedForm"
      @cancel="closeModalDelete"
      okText="Delete"
      cancelText="Cancel"
      :processing="isLoadingDelete"
      okClass="btn btn-danger"
    >
      <span slot="title" class="text-danger font-weight-bold">Delete Install</span>

      <div>Confirm you want to delete the embedded form for <span v-if="selectedDeleteForm" class="text-danger"><b>{{ selectedDeleteForm.name }}</b></span> ?</div>
    </vuestic-modal>
  </div>
</template>
<script>
import SearchInput from '../../common/SearchInput'
import Action from './Action'
import TdBoolean from '@/components/common/tables/comps/td-Boolean'
import TdToggle from '@/components/common/tables/comps/td-Toggle'


export default {
  components: {
    SearchInput,
    Action,
    TdBoolean,
    TdToggle
  },

  created () {
    this.ipTableOptions.xprops.eventbus.$on('openShowHTMLCodeModal', (embeddedForm) => { this.selectedForm = embeddedForm })
    this.ipTableOptions.xprops.eventbus.$on('toggleActive', this.toggleActive)
  },

  data () {
    return {
      isLoading: false,
      isOpenModal: false,
      isLoadingSave: false,
      isOpenModalDelete: false,
      selectedBlacklist: null,
      isLoadingDelete: false,
      selectedForm: null,
      ipTableOptions: {
        supportBackup: false,
        supportNested: false,
        HeaderSettings: false,
        tblClass: "table-bordered",
        tblStyle: "color: #666",
        pageSizeOptions: [5, 10, 25, 50, 100],
        columns: (() => {
          return [
            { title: "ID", field: "id", label: "ID", tdClass: "center", sortable: true, visible: true },
            { title: "Name", field: "name", label: "Name", tdClass: "center", sortable: true, visible: true },
            { title: "Website", field: "website", label: "Website", tdClass: "center", sortable: false, visible: true },
            { title: "Type", field: "type_str", label: "Type", tdClass: "center", sortable: false, },
            { title: "Department", field: "department_str", label: "Department", tdClass: "center", sortable: false, },
            { title: 'Installed', field: 'is_installed',tdComp: TdBoolean,sortable: true, },
            { title: 'Active', field: 'is_active',tdComp: TdToggle,sortable: true, },
            { title: '', tdComp: Action, visible: 'true', tdClass: 'center', },
          ]
        })(),
        data: [],
        total: 0,
        summary: {},
        query: {},
        xprops: {
          eventbus: new Vue()
        }
      },
      newBlacklist: {},
      isShowImportModal: false,
      isLoadingImport: false,
      importData: {
        attach: null,
      },
      selectedDeleteForm: null,
    }
  },

  watch: {
    'ipTableOptions.query': {
      handler() {
        this.loadData()
      },
      deep: true
    },
  },

  mounted () {
    this.ipTableOptions.xprops.eventbus.$on('openDeleteModal', row => {
      this.openModalDelete(row)
    })
  },

  methods: {
    onClickImport() {
      this.importData = {
        attach: null,
      }
      this.isShowImportModal = true;
    },
    handleImport() {
      if (!this.importData.attach) return;

      this.isLoadingImport = true

      let importForm = new FormData()
      importForm.append('attach', this.importData.attach)

      this.$store.dispatch('blacklist/import', importForm)
        .then(() => {
          this.isLoadingImport = false
          this.isShowImportModal = false
          this.loadData()
        })
        .catch(() => {
          this.isLoadingImport = false
        })
    },
    onUploadFile(event) {
      if (event.file) {
        this.importData.attach = event.file
      }
    },
    onDeleteFile(event) {
      this.importData.attach = null
    },

    openModal () {
      this.isOpenModal = true
    },
    
    closeModal () {
      this.isOpenModal = false
    },

    loadData () {
      this.isLoading = true
      const { query } = this.ipTableOptions;
      const page = query.offset ? Math.floor(query.offset /  query.limit) + 1 : 1;
      const param = {
        page,
        per_page: query.limit,
        order: query.order,
        sort: query.sort,
        install_id: this.$route.params.install,
      }

      this.$store.dispatch('install/allForms', param)
        .then((res) => {
          this.ipTableOptions.data = res.data
          this.ipTableOptions.total = res.meta.total
          this.isLoading = false
        })
        .catch((e) => {
          this.isLoading = false
          this.$router.push({ name: 'business.installs.active' });
        })
    },

    handleAddBlacklist () {
      this.isLoadingSave = true
      this.$store.dispatch('blacklist/create', this.newBlacklist)
        .then(() => {
          this.newBlacklist = {}
          this.closeModal()
          this.loadData()
          this.isLoadingSave = false
        })
        .catch(() => {
          this.isLoadingSave = false
        })
    },

    copyEmbedCode () {
      document.getElementById('embedCode').select()
      document.execCommand('copy')

      Vue.$toast.open({
        message: 'Copied',
        type: 'success',
      })
      this.selectedForm = null
    },

    openModalDelete (embeddedForm) {
      this.isOpenModalDelete = true
      this.selectedDeleteForm = embeddedForm
    },

    closeModalDelete () {
      this.isOpenModalDelete = false
      this.selectedDeleteForm = null
    },

    handleDeleteEmbeddedForm () {
      this.isLoadingDelete = true

      this.$store.dispatch('install/deleteForm', this.selectedDeleteForm)
        .then(() => {
          this.closeModalDelete()
          this.loadData()
          this.isLoadingDelete = false
        })
        .catch(() => {
          this.isLoadingDelete = false
        })
    },

    toggleActive (args) {
      this.$store.dispatch('install/toggleActiveForm', { id: args.id, install_id: this.$route.params.install })
        .then(() => {
        })
        .catch(() => {
        })
      
    },
  },
}
</script>
<style lang="scss">
  .blacklist-page {
    .widget-body {
      padding: 0;
    }
    .nav-item {
      min-width: 120px;
      text-align: center;
    }

    #embedCode {
      border-bottom: none;
    }
  }
</style>
